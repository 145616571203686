import React from "react";
import GATSBY_COMPILED_MDX from "/vercel/path0/gatsby/web/content/pages/terms-of-service.mdx";
import {graphql} from "gatsby";
import PageComponent from "../components/page";
PageComponent
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageComponent, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query ($slug: String!) {
    page(slug: { eq: $slug }) {
      id
      title
      slug
      excerpt
      cover {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
    }
  }
`;
